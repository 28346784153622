'use strict'

import Polyfills from './Polyfills.js'
import Bartender from '@fokke-/bartender.js'
import boringmenu from '@teppokoivula/boringmenu'
import Glightbox from 'glightbox'
import SimpleBar from 'simplebar'
import axios from 'axios'
import Swiper from 'swiper'
import { Navigation, Autoplay } from 'swiper/modules'

/**
 * Site class contains general purpose site-specific features.
 *
 * @version 1.1.0
 */
export default class Site {

	/**
	 * Class constructor
	 *
	 * @param {Object} options Options for the class.
	 */
	constructor (options = {}) {
		this.options = {}

		// Init polyfills
		const polyfills = new Polyfills()
		polyfills.init()

		// Init Bartender after mobile menu is ready
		document.addEventListener('boringmenu-init-done', () => {
			window.bartender = new Bartender({
				debug: false,
				trapFocus: true,
				overlay: true,
			})
		})

		// Init mobile menu
		const mobileMenu = document.getElementById('mobile-menu')
		new boringmenu({
			selectors: {
				menu: '.menu-mobile__list--level-1',
			},
			classes: {
				item: 'menu-mobile__item',
				itemActive: 'menu-mobile__item--current',
				itemParent: 'menu-mobile__item--parent',
				toggle: 'menu-mobile__toggle',
				toggleTextContainer: 'sr-only',
			},
			labels: {
				'menu.open': mobileMenu ? mobileMenu.getAttribute('data-labels-open') : 'open',
				'menu.close': mobileMenu ? mobileMenu.getAttribute('data-labels-close') : 'close',
			},
			icons: {
				'menu.open': 'icon-open',
				'menu.close': 'icon-close',
			},
		})

		// Initialize
		this.init(options)
	}

	/**
	 * Init the class by calling applicable init methods
	 *
	 * @param {Object} options Options for the class.
	 * @return {Object}
	 */
	init (options = {}) {

		// Merge user options to the defaults
		this.options = {
			responsiveTables: {
				selector: 'main table',
			},
			imageLinks: {
				parentSelector: 'main',
			},
			...options,
		}

		// Call individual init methods
		this.initResponsiveTables()
		this.initSkipLinks()
		this.initImageLinks()
		this.initHorizontalScrollers()
		this.initLiveSearch()
		this.initHighlightsSwiper()

		// Category nav link
		const categoryMenuSearchLink = document.getElementById('category-menu__searchLink')

		if (categoryMenuSearchLink) {
			categoryMenuSearchLink.addEventListener('click', (e) => {
				e.preventDefault()
			})
		}

		// Dispatch custom event when init is done
		document.dispatchEvent(
			new CustomEvent('site-init-done', {
				bubbles: true,
				cancelable: true,
			})
		)

		return this
	}

	initHighlightsSwiper () {
		const swiper = new Swiper('.nav-highlights-swiper', {
			modules: [Autoplay, Navigation],
			direction: 'horizontal',
			slidesPerView: 1,
			spaceBetween: 32,
			rewind: false,
			loop: false,

			autoplay: {
				delay: 5500,
				disableOnInteraction: false,
			},

			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
				clickable: true,
			},
		})
	}

	/**
	 * Initialize responsive tables
	 *
	 * Finds content tables and wraps them with div.table-wrapper.
	 */
	initResponsiveTables () {
		document.querySelectorAll(this.options.responsiveTables.selector).forEach(table => {
			if (!table.closest('.table-wrapper')) {
				const tableWrapper = document.createElement('div')
				tableWrapper.classList.add('table-wrapper')
				tableWrapper.classList.add('overflow-x-auto')
				table.parentNode.insertBefore(tableWrapper, table)
				tableWrapper.appendChild(table)
			}
		})
	}

	/**
	 * Initialize skip links
	 *
	 * Finds skip links and enhances their behaviour for various screen readers and mobile devices.
	 */
	initSkipLinks () {
		const skipLinks = document.querySelectorAll('.skip-link:not([data-skip-link])')
		if (skipLinks.length) {
			const skipToBlur = event => {
				if (event.target.getAttribute('data-tabindex')) {
					event.target.removeAttribute('tabindex')
					event.target.removeAttribute('data-tabindex')
				}
			}
			skipLinks.forEach(skipLink => {
				skipLink.setAttribute('data-skip-link', true)
				skipLink.addEventListener('click', event => {
					const skipTo = document.getElementById(event.target.href.split('#')[1])
					if (skipTo && skipTo.getAttribute('tabindex') != '-1') {
						event.preventDefault()
						skipTo.setAttribute('tabindex', '-1')
						skipTo.setAttribute('data-tabindex', true)
						skipTo.addEventListener('blur', skipToBlur)
						skipTo.addEventListener('focusout', skipToBlur)
						skipTo.focus()
					}
				})
			})
		}
	}

	/**
	 * Initialize image links
	 */
	initImageLinks () {

		// Parent of image links
		let parentNode = document.querySelector(this.options.imageLinks.parentSelector)
		if (!parentNode) return

		// Add glightbox class to image links
		parentNode.querySelectorAll('a[href$=".jpg"], a[href$=".jpeg"], a[href$=".png"], a[href$=".gif"]').forEach(link => {
			if (!link.classList.contains('glightbox')) {
				if (!link.getAttribute('data-title') && !link.getAttribute('data-glightbox')) {
					let figcaption = link.parentNode.querySelector('figcaption')
					if (figcaption) {
						let caption = figcaption ? figcaption.textContent : ''
						link.setAttribute('data-title', caption)
					}
				}
				link.classList.add('glightbox')
			}
		})

		// Initialize GLightbox
		if (parentNode.querySelector('.glightbox')) {
			window.glightbox = Glightbox()
		}
	}

	/**
	 * Helper function for scrolling element
	 *
	 * @param {object} el Element to scroll
	 * @param {string} dir Scroll direction
	 * @returns {number} Initialized interval
	 */
	startScroll (el, dir = 'left') {
		return setInterval((e) => {
			if (dir == 'left') {
				el.scrollTo((el.scrollLeft - 10), 0)
			} else {
				el.scrollTo((el.scrollLeft + 10), 0)
			}
		})
	}

	/**
	 * Initialize horizontal scrollers
	 */
	initHorizontalScrollers () {
		const items = document.querySelectorAll('.horizontal-scroller')
		if (!items.length) return

		items.forEach(item => {
			const scroller = item.querySelector('.horizontal-scroller__scroller')
			if (!scroller) return

			const simpleBar = new SimpleBar(scroller, {
				autoHide: false,
				scrollbarMaxSize: 300,
			})

			const scrollEl = simpleBar.getScrollElement()
			const leftScrollButton = item.querySelector('.horizontal-scroller__arrow--left')
			const rightScrollButton = item.querySelector('.horizontal-scroller__arrow--right')
			let leftScrollInterval
			let rightScrollInterval

			// Left scroll button
			if (leftScrollButton) {
				leftScrollButton.addEventListener('mousedown', () => {
					leftScrollInterval = this.startScroll(scrollEl, 'left')
				})

				leftScrollButton.addEventListener('touchstart', () => {
					leftScrollInterval = this.startScroll(scrollEl, 'left')
				}, {
					passive: true,
				})

				leftScrollButton.addEventListener('mouseup', () => {
					clearInterval(leftScrollInterval)
				})

				leftScrollButton.addEventListener('touchend', () => {
					clearInterval(leftScrollInterval)
				})
			}

			// Right scroll button
			if (rightScrollButton) {
				rightScrollButton.addEventListener('mousedown', () => {
					rightScrollInterval = this.startScroll(scrollEl, 'right')
				})

				rightScrollButton.addEventListener('touchstart', () => {
					rightScrollInterval = this.startScroll(scrollEl, 'right')
				}, {
					passive: true,
				})

				rightScrollButton.addEventListener('mouseup', () => {
					clearInterval(rightScrollInterval)
				})

				rightScrollButton.addEventListener('touchend', () => {
					clearInterval(rightScrollInterval)
				})
			}

			// When container is being scrolled...
			scrollEl.addEventListener('scroll', (e) => {
				// If we have reached the left side
				if (leftScrollButton) {
					if (e.target.scrollLeft <= 0) {
						// Hide left scroll button
						leftScrollButton.classList.remove('horizontal-scroller__arrow--visible')
						leftScrollButton.setAttribute('aria-hidden', true)

						// Stop timer
						clearInterval(leftScrollInterval)
					} else {
						// Show left scroll button
						leftScrollButton.classList.add('horizontal-scroller__arrow--visible')
						leftScrollButton.removeAttribute('aria-hidden')
					}
				}

				// If we have reached the right side
				if (rightScrollButton) {
					if ((e.target.scrollLeft + e.target.offsetWidth) >= e.target.scrollWidth) {
						// Hide right scroll button
						rightScrollButton.classList.remove('horizontal-scroller__arrow--visible')
						rightScrollButton.setAttribute('aria-hidden', true)

						// Dispatch event to stop timers
						clearInterval(rightScrollInterval)
					} else {
						// Show right scroll button
						rightScrollButton.classList.add('horizontal-scroller__arrow--visible')
						rightScrollButton.removeAttribute('aria-hidden')
					}
				}
			})

			// Initially dispatch scroll event to evaluate buttons
			scrollEl.dispatchEvent(new Event('scroll'))

			item.classList.add('horizontal-scroller--ready')
		})
	}

	/**
		* Initialize live search forms
		*/
	initLiveSearch () {
		const elements = document.querySelectorAll('.liveSearch')

		if (!elements.length) return

		elements.forEach(element => {
			const form = element.querySelector('.liveSearch__form')
			if (!form) return

			const actionUrl = form.getAttribute('action')
			if (!actionUrl) return

			const method = form.getAttribute('method')
			if (!method) return

			const input = element.querySelector('.liveSearch__input')
			if (!input) return

			const appendToEl = element.querySelector('.liveSearch__results')
			if (!appendToEl) return

			// Prevent default submit
			element.addEventListener('submit', e => {
				e.preventDefault()
			})

			// Monitor search query changes
			let inputTimeout = null

			input.addEventListener('input', e => {
				clearTimeout(inputTimeout)

				let searchQuery = e.target.value

				inputTimeout = setTimeout(() => {
					element.classList.add('liveSearch--loading')

					axios({
						method: method,
						url: actionUrl,
						headers: { 'X-Requested-With': 'XMLHttpRequest' },
						params: {
							q: searchQuery,
						},
					}).then(response => {
						appendToEl.innerHTML = response.data
					}).catch(error => {
						console.error(error)
					}).then(() => (
						element.classList.remove('liveSearch--loading')
					))
				}, 200)
			})
		})
	}

}
